import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'
import { useRelatedItems } from '@/storyblok/hooks/useRelatedItems'
import { getRichText } from '@/storyblok/helpers/getRichText'
import { getProject } from '@/storyblok/helpers/getProject'
import { getMedia } from '@/storyblok/helpers/getMedia'
import { useProjectTags } from '@/storyblok/hooks/useProjectTags'
import { useBloks } from '@/storyblok/hooks/useBloks'
import { BlockRenderer } from '@/storyblok/components/BlockRenderer'
import { RelatedThumbs } from '@/components/RelatedThumbs'
import Project from '@/templates/Project'
import { blockTypes } from '@/constants/blockTypes'

const ProjectStoryblok = ({ story, projects }) => {
  const tags = useProjectTags()
  const blocks = useBloks(story.content.body || [])

  const details = []
  story.content.details.forEach((item) => {
    details.push({
      uid: item._uid,
      heading: item.heading,
      description: getRichText(item.description),
    })
  })

  const relatedItems = useRelatedItems({
    items: projects,
    currentUid: story.uuid,
    maxItems: 4,
    categories: story.content?.categories,
    getNode: (node) => getProject(node, tags),
  })

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Project
        title={story.name}
        intro={getRichText(story.content.intro)}
        headerMedia={
          !!story.content.header_media?.length
            ? getMedia(story.content.header_media[0], { maxWidth: 1200 })
            : null
        }
        description={getRichText(story.content.description)}
        details={details}
      >
        {!!blocks.length && (
          <BlockRenderer blocks={blocks} types={blockTypes} />
        )}
        {!!RelatedThumbs.length && (
          <RelatedThumbs heading="More projects" items={relatedItems} />
        )}
      </Project>
    </SbEditable>
  )
}

ProjectStoryblok.defaultProps = {
  projects: [],
}

ProjectStoryblok.propTypes = {
  story: PropTypes.object,
  projects: PropTypes.arrayOf(PropTypes.object),
}

export default ProjectStoryblok
