import { sortBy } from '@/utils/sortBy'

/**
 * Get similar items based on uid and categories
 *
 * Based on:
 * https://khalilstemmler.com/articles/gatsby-related-posts-component/
 *
 * @param {object} options
 */

export const useRelatedItems = ({
  items,
  currentUid,
  maxItems = 3,
  categories = [],
  getNode = (node) => node,
}) => {
  let output = []

  // Remove the current item in item array
  const filteredItems = items.filter((i) => {
    return getNode(i.node).uid !== currentUid
  })

  // We use an Identity Map to keep track of score
  const identityMap = {}

  // Map over all items, add to map and add points
  filteredItems.forEach((i) => {
    // use getNode function to parse item data
    const item = getNode(i.node)
    const { uid } = item

    // Add to Map
    if (!Object.prototype.hasOwnProperty.call(identityMap, uid)) {
      identityMap[uid] = item
      identityMap[uid].points = 0
    }

    // Add Category Points
    if (!!item.categories?.length) {
      item.categories.forEach((category) => {
        if (categories.indexOf(category.uid) > -1) {
          identityMap[uid].points += 1
        }
      })
    }
  })

  // Convert the identity map to an array
  const arrayIdentityMap = Object.keys(identityMap).map(
    (uid) => identityMap[uid]
  )

  // Sort arry by points, from greatest to least
  output = sortBy(arrayIdentityMap, 'points').reverse()

  // Take the max number items requested
  return output.splice(0, maxItems)
}
